.custom-date-picker .date-wrapper {
    position: relative;
    min-width: 220px;
}

.custom-date-picker input[type="date"] + .clear-input {
    position: absolute;
    line-height: 2.375rem;
    opacity: 0;
    top: 0;
    right: 2.6rem;
    height: 1rem;
    width: 1rem;
    transition: opacity .1s ease-out;
}

.custom-date-picker input[type="date"].form-control-sm + .clear-input {
    line-height: 2rem;
}

.custom-date-picker input[type="date"].form-control-lg + .clear-input {
    line-height: 3rem;
}

.custom-date-picker input[type="date"]:valid + .clear-input {
    cursor: pointer;
    opacity: 1;
}

.custom-date-picker input[type=date]::-webkit-calendar-picker-indicator {
    position: absolute;
    cursor: pointer;
    height: 1.1rem;
    width: 1.1rem;
    right: 0.75rem;
    top: 0.5rem;
}

.custom-date-picker input[type=date].form-control-sm::-webkit-calendar-picker-indicator {
    top: 0.325rem;
}

.custom-date-picker input[type=date].form-control-lg::-webkit-calendar-picker-indicator {
    top: 0.875rem;
}

.custom-date-picker input.form-control.is-invalid {
    background-position: right calc(0.375em + 2.1875rem) center;
}