.navbar + * {
    margin: 56px auto 0;
    min-height: calc(100vh - 56px);
}

.navbar-dark .navbar-brand {
    padding-top: 2px;
    padding-bottom: 2px;
}

.navbar-dark .navbar-brand a {
    color: #fff;
}

.navbar-dark .navbar-brand a:hover {
    text-decoration: none;
}

.navbar a, .navbar span {
    user-select: none;
}

.navbar-nav .nav-link {
    white-space: nowrap;
}

.navbar-nav .nav-link span {
    padding: 0 .5em;
}

.navbar-collapse .dropdown-toggle::after {
    content: none !important;
}

.menu-user-box:hover {
    color: rgba(255,255,255,.5) !important;
}

.navbar-collapse .dropdown-menu {
    left: auto;
    right: 0;
}

.dropdown-menu .dropdown-item .icon {
    margin-right: .5rem;
    width: 1rem;
}

.dropdown-menu .dropdown-item .icon.flag {
    width: 1.5rem;
}

.nav-item.dropdown .dropdown-toggle::after {
    margin-left: 0;
    vertical-align: .125em;
}

.nav-item.dropdown .dropdown-item .small-column {
    display: inline-block;
    min-width: 64px;
}