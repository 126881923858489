.select-wrapper {
    position: relative;
    width: 100%;
}

.select-wrapper input[type="checkbox"].select-has-value {
    display: none;
}

.select-wrapper select.custom-select ~ .clear-input {
    position: absolute;
    line-height: 2.375rem;
    opacity: 0;
    right: 2.3rem;
    height: 1rem;
    width: 1rem;
    transition: opacity .1s ease-out;
}

.select-wrapper select.custom-select.custom-select-sm ~ .clear-input {
    line-height: 2rem;
}

.select-wrapper select.custom-select.custom-select-lg ~ .clear-input {
    line-height: 3rem;
}

.select-wrapper input[type="checkbox"].select-has-value:checked + .clear-input {
    cursor: pointer;
    opacity: 1;
}

.select-inner-wrapper .loading-input {
    position: absolute;
    left: 1rem;
    top: 0;
    line-height: 2.375rem;
}

.select-inner-wrapper {
    position: relative;
    width: 100%;
}
