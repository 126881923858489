body {
    overflow-y: scroll;
}

.capitalize {
    text-transform: capitalize;
}

.faSpin {
    animation: spin infinite .8s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadOverlay {
    height: 3rem !important;
    width: 3rem !important;
    position: fixed;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
    z-index: 9999;
 }

 .loadBtn {
    position: relative;
 }

 .loadBtn .faSpin {
    position: absolute;
    height: 1.5rem;
    left: calc(50% - 0.5rem);
 }

/* Colors */

.btn-primary {
    color: #fff !important;
    background-color: #027bc2 !important;
    border-color: #027bc2 !important;
}

.bg-color-blue {
    background-color: #027bc2 !important;
}

.border-color-blue {
    border-color: #027bc2 !important;
}

.color-blue {
    color: #027bc2 !important;
}

.__react_component_tooltip {
    padding: .25rem 1rem !important;
    font-weight: normal !important;
}

.__react_component_tooltip::before {
    background-color: transparent !important;
}