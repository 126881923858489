.notification-list {
    position: fixed;
    top: 5rem;
    right: 2rem;
    width: 30rem;
    max-width: 84vw;
    min-width: none;
    z-index: 999;
}

@media (max-width: 575px) {
    .notification-list {
        z-index: 1031;
    }
}

.notification-list .notification {
    max-width: none;
    width: 100%;
}

.notification-list .notification .toast-header {
    border-top: .25rem solid;
}

.notification-list .notification .toast-body {
    color: #6c757d;
    white-space: pre-line;
    background-color: rgba(255, 255, 255, 0.7);
}

.notification-list .notification .toast-header .toast-title {
    color: #6c757d;
    margin: 0 .375rem;
}

.notification-list .notification.notificationSuccess .toast-header,
.notification-list .notification.notificationSuccess .toast-header .icon {
    color: #01af00;
    border-top-color: #01af00;
}

.notification-list .notification.notificationWarning .toast-header,
.notification-list .notification.notificationWarning .toast-header .icon {
    color: #f0ba00;
    border-top-color: #f0ba00;
}

.notification-list .notification.notificationError .toast-header,
.notification-list .notification.notificationError .toast-header .icon {
    color: #e00000;
    border-top-color: #e00000;
}

.notification-list .notification.notificationInfo .toast-header,
.notification-list .notification.notificationInfo .toast-header .icon {
    color: #0086d5;
    border-top-color: #0086d5;
}

.notification-list .notification .toast-body strong {
    font-weight: 500;
}