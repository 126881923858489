.search-page {
    display: flex;
    flex-direction: column-reverse;
    width: 85%;
}

.search-page > div {
    display: flex;
    justify-content: center;
    transition: flex-grow .25s ease-out;
}

.search-page .search-header {
    align-items: flex-end;
    flex-grow: 1;
}

.search-page .search-bar {
    flex-grow: 0;
}

.search-page .search-result {
    overflow: hidden;
    flex-grow: 1;
    margin-top: 4rem;
    z-index: 1;
    scroll-margin-top: 4rem;
}

.search-page .search-result > div {
    flex-grow: 1;
    max-height: 0;
    transition: max-height .5s ease-out;
}

.search-page .search-result.active {
    overflow: visible;
}

.search-page .search-result.active > div {
    max-height: 100vh;
}

/* .search-page .search-result.active ~ div.search-header {
    flex-grow: 0;
} */

.no-result-message-box {
    margin: auto;
    max-width: 900px;
    width: 100%;
}