.page-not-found {
    margin-left: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-not-found h1 {
    font-size: 10rem;
}

.page-not-found h2 {
    font-size: 3rem;
}

.page-not-found p.message {
    margin-top: 1rem;
}

.page-not-found p.contactUs {
    margin-top: 10rem;
}